export enum ActionType {
  GET_USER = "get_user",
  CREATE_USER = "create_user",
  GET_ALL_INGREDIENTS = "get_all_ingredients",
  LOGIN_USER = "login_user",
  GET_ING_BY_NAME = "get_ing_by_name",
  GET_USERS_MEALS = "get_users_meals",
  GET_MEALS_INGREDIENTS = "get_meals_ingredients",
  GET_MEALS_STEPS = "get_meals_steps",
  GET_MEAL_BY_ID = "get_meal_by_id",
  GET_PLANE_DAYS = "get_plan_days",
  GET_ALL_MEALS_IN_DAYS = "get_all_meals_in_days",
  CLEAN_STATE = "clean_state",
  GET_PLAN_BY_ID = "get_plan_by_id",
  GET_TODAYS_MEALS = "get_todays_meals",
  CLEAR_SEARCH = "clear_search",
  GET_ALL_SHOPPING_ITEMS = "get_all_shopping_items",
  GET_SHOPPING_ITEMS_BY_USER_ID = "get_shopping_items_by_user_id",
  GET_ALL_PLANS = "get_all_plans",
  GET_BASIC_FOOD = "get_basic_food",
}
