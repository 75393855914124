const routes = {
  UPDATE_USER: "/update_user",
  CREATE_USER: "/create_user",
  GET_USER_BY_ID: "/get_user_by_id",
  LOGIN_USER: "/login_user",
  GET_ALL_USERS: "/get_all_users",
  GET_ALL_DAYS: "/get_all_days",
  GET_DAY_BY_ID: "/get_day_by_id",
  CREATE_DAY: "/create_day",
  DELETE_DAY: "/delete_day",
  UPDATE_DAY: "/update_day",
  GET_ALL_PLANS: "/get_all_plans",
  GET_PLAN_BY_ID: "/get_plan_by_id",
  CREATE_PLAN: "/create_plan",
  DELETE_PLAN: "/delete_plan",
  UPDATE_PLAN: "/update_plan",
  GET_ALL_USERS_INGREDIENT: "/get_all_users_ingredients",
  GET_USERS_INGREDIENT_BY_ID: "/get_users_ingredients_by_id",
  CREATE_USERS_INGREDIENT: "/create_users_ingredient",
  DELETE_USERS_INGREDIENT: "/delete_users_ingredient",
  UPDATE_USERS_INGREDIENT: "/update_users_ingredient",
  GET_ALL_MEALS: "/get_all_meal",

  GET_MEAL_BY_ID: "/get_meal_by_id",
  GET_MEAL_BY_USER_ID: "/get_users_meals",
  CREATE_MEAL_STEP: "/add_meal_step",
  GET_MEALS_STEPS: "/get_meals_steps",
  DELETE_MEAL: "/delete_meal",
  CREATE_MEAL: "/create_meal",
  UPDATE_MEAL: "/update_meal",
  GET_ALL_STEPS: "/get_all_steps",
  GET_STEPS_BY_ID: "/get_steps_by_id",
  CREATE_STEP: "/create_step",
  DELETE_STEP: "/delete_step",
  UPDATE_STEP: "/update_step",
  GET_ALL_INGREDIENTS: "/get_all_ingredients",
  GET_INGREDIENTS_BY_NAME: "/get_ingredient_by_name",
  GET_INGREDIENT_BY_ID: "/get_ingredient_by_id",
  ADD_INGREDIENT: "/add_ingredients",
  DELETE_INGREDIENT: "/delete_ing",
  UPDATE_INGREDIENT: "/update_ingredient",
  PURCHASE_INGREDIENT: "/purchase_made",
  EDIT_INGREDIENT: "/edit_ingredients",
  CREATE_INGREDIENT_IN_MEAL: "/ingredient_in_meal",
  GET_MEAL_INGREDIENTS: "/get_meals_ingredients",
};
export default routes;
