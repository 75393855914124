export const color = {
  mainBlue: "#577cff",
  mainRed: "#ff4242",
  buttonDefault: "#577cff",
  buttonDanger: "#ff4242",
  buttonDisabled: "#aaaaaa",
  buttonOk: "#57FF7C",
  orangeMain: "#fd5f3d",
  fontDark: "#555",
  fontLight: "#9b9b9b",
  proteinColor: "#9163e3",
  carbsColor: "#e7bb43",
  fatColor: "#81bfbb",
  silverBox: "#f3f3f3",
};
